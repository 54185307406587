import { Typography, TypographyProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Link } from "../navigation/Link";
import { makeStyles } from "../style/makeStyles";

const useStyles = makeStyles()((theme) => ({
    title: {
        marginBottom: 30,
        fontWeight: "bold",
    },
    bold: { fontWeight: "bold" },
    italic: { fontStyle: "italic" },
    small: { fontSize: "0.7em" },
    bodyText: {
        "&.MuiTypography-gutterBottom": {
            marginBottom: "0.8em",
        },
    },
    link: {
        "&:any-link, &:-webkit-any-link": {
            color: theme.palette.primary.main,
        },
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

const useMuiColor = (color: string | undefined): TypographyProps["color"] | undefined => {
    const theme = useTheme();
    const { palette } = theme;

    const paletteKeys = Object.keys(palette);
    return color && paletteKeys.includes(color) ? (color as TypographyProps["color"]) : undefined;
};

export type TextBaseProps = {
    className?: string;
    color?: string;
    bold?: boolean;
    italic?: boolean;
    small?: boolean;
    component?: React.ElementType;
    // used for the weird link behavior
    newTab?: boolean;
    href?: string;
    target?: string;
    rel?: string;
} & TypographyProps;
export const TextBase = ({
    className,
    color,
    bold = false,
    italic = false,
    small = false,
    href,
    newTab,
    ...props
}: TextBaseProps) => {
    const { classes, cx } = useStyles();

    const muiColor = useMuiColor(color);
    const classNames = cx(bold && classes.bold, italic && classes.italic, small && classes.small, className);

    if (href) {
        const link = (
            <Typography
                className={classNames}
                color={muiColor}
                {...props}
                sx={{ textDecoration: "underline", color: "text.secondary" }}
                component="span"
            />
        );

        return (
            <Link href={href} newTab={newTab}>
                {link}
            </Link>
        );
    }

    return <Typography className={classNames} color={muiColor} {...props} />;
};
export const Text = ({ className, ...props }: TextBaseProps) => {
    const { classes, cx } = useStyles();
    return <TextBase className={cx(classes.bodyText, className)} {...props} />;
};

export const TextTitle = ({ className, ...props }: TextBaseProps) => {
    const { classes, cx } = useStyles();

    return <TextBase variant="h1" color="primary" className={cx(classes.bodyText, className)} {...props} />;
};

export const TextTitle2 = (props: Omit<TextBaseProps, "variant">) => (
    <TextTitle variant="h2" color="secondary" {...props} />
);

export const TextTitle3 = (props: Omit<TextBaseProps, "variant">) => <TextTitle variant="h3" {...props} />;

export const TextTitle4 = (props: Omit<TextBaseProps, "variant">) => (
    <TextTitle variant="h4" color="initial" {...props} />
);

export const TextBody = (props: Omit<TextBaseProps, "variant">) => <Text variant="body1" {...props} />;

export const TextBody1 = (props: Omit<TextBaseProps, "variant">) => <Text variant="body2" {...props} />;

export const TextSubtitle = (props: Omit<TextBaseProps, "variant">) => <Text variant="subtitle1" {...props} />;

export const TextSubtitle2 = (props: Omit<TextBaseProps, "variant">) => <Text variant="subtitle1" {...props} />;

export const TextCaption = (props: Omit<TextBaseProps, "variant">) => <Text variant="caption" {...props} />;

export const TextPrice = (props: Omit<TextBaseProps, "noWrap">) => <Text noWrap {...props} />;

export const TextLink = ({ className, ...props }: TextBaseProps) => {
    const { classes, cx } = useStyles();
    const { href } = props;
    return <Text className={cx(className, href && classes.link)} {...props} />;
};
